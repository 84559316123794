import { h } from 'preact';
import { Router, route } from 'preact-router';

import Header from './header';
import { storageHandler } from '../util/storage_handler';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import QRscanner from '../routes/qrscanner';
import Login from '../routes/login';
import LoginSMS from '../routes/login-sms';
import Register from '../routes/register';
import ContactUs from '../routes/contact-us';

async function isAuthenticated() {
	const urlParams = new URLSearchParams(location.search);
	const code = urlParams.get("code");
	const state = urlParams.get("state");
	if(code){
		storageHandler.localStorageSet("code", code);
	}
	if(state){
		storageHandler.localStorageSet("state", state);
	}
	if(code || state){
        window.history.pushState({}, "Hide", window.location.origin);
	}
	return typeof storageHandler.localStorageGet("code") !== "undefined";
}

const handleRoute = async e => {
	const isAuthed = await isAuthenticated();
    if (!e.url.includes("/login")) {
        if (!isAuthed) {
			route(`/login`, true);
		}
    } else {
		if (isAuthed) {
			route('/', true);
		}
	}
};

const App = () => (
	<div id="app">
		<Header />
		<Router /*onChange={handleRoute}*/>
			<Home path="/" />
			<Profile path="/profile/" user="me" />
			<Profile path="/profile/:user" />
			<QRscanner path="/qrscanner" />
			<Login path="/login" />
			<LoginSMS path="/login-sms"/>
			<Register path="/register"/>
			<ContactUs path="/contact-us" />
		</Router>
	</div>
)

export default App;
